<script setup lang="ts">
import type { PropType } from "vue";

import type { ITab } from "~/composable/tab.type";
import Text from "~/components/text/text.vue";
import EqZaimIcon from "~/components/icons/eqZaimIcon.vue";
defineProps({
  items: {
    type: Object as PropType<ITab[]>,
    required: true,
  },
  title: {
    type: String,
    required: true,
    default: "",
  },
  description: {
    type: String,
    default: "",
  },
});
const currentTab = ref(0);
</script>

<template>
  <div class="eqzaim-tabs">
    <div class="eqzaim-tabs-title">
      <h2 class="eqzaim-tabs-title__text">{{ title }}</h2>
      <div class="eqzaim-tabs__elements" v-if="items.length > 1">
        <div class="eqzaim-tabs__elements-wrapper">
          <template v-for="(item, index) in items" :key="index">
            <a
              href="#"
              @click.prevent="currentTab = index"
              :class="{
                'eqzaim-tabs__elements-link--active': currentTab === index,
              }"
              class="eqzaim-tabs__elements-link"
              >{{ item.title }}</a
            >
          </template>
        </div>
      </div>
    </div>
    <transition-group>
      <template v-for="(item, index) in items" :key="index">
        <Slider
          :show-navs="false"
          :show-title="false"
          v-show="currentTab === index"
        >
          <template #default>
            <swiper-slide
              lazy="true"
              v-for="(slide, index) in item.tabContent"
              :key="index"
              class="eqzaim-swiper__item"
            >
              <div class="eqzaim-slide">
                <div class="eqzaim-slide__step" v-if="item?.stepsName">
                  <span>{{ item?.stepsName }} {{ ++index }}</span>
                </div>
                <div
                  v-show="slide.title !== ''"
                  class="eqzaim-slide__title"
                  v-html="slide.title"
                />
                <div class="eqzaim-slide__text">
                  <Text
                    title=""
                    description=""
                    v-if="slide.mainText !== null"
                    :items="{ code: item.code, text: slide.mainText }"
                  />
                </div>
                <img
                  :src="slide.image"
                  :alt="slide.title"
                  v-if="slide.image"
                  loading="lazy"
                  class="eqzaim-slide__image"
                />
                <EqZaimIcon
                  :icon-name="index <= 3 ? 'arrow-right' : 'tick-square'"
                  class="eqzaim-slide__icon"
                />
              </div>
            </swiper-slide>
          </template>
        </Slider>
      </template>
    </transition-group>
  </div>
</template>

<style scoped lang="postcss">
.eqzaim-tabs {
  @apply container m-auto mb-[32px] overflow-hidden;
  &-title {
    @apply flex justify-between mb-[44px] md:flex-row flex-col gap-16px;

    &__text {
      @apply xl:text-heading-L md:text-heading-XS text-heading-XXXS text-primary-black border-2 border-transparent;
    }
  }
}
.eqzaim-tabs__elements {
  @apply flex md:items-end md:flex-row flex-col text-normal-S md:text-normal-M;

  &-wrapper {
    @apply gap-6px bg-secondary-gray-50 flex md:rounded-desktop-internal rounded-mobile-external flex-col md:flex-row;
  }

  &-link {
    @apply md:px-14px md:py-8px px-24px py-12px transition-all border-2 border-transparent text-secondary-gray;

    &--active {
      @apply bg-white md:rounded-desktop-internal rounded-mobile-external border-2 border-secondary-gray-50 text-primary-black;
    }
  }
}

.eqzaim-slide {
  @apply bg-secondary-block-blue p-16px rounded-slider text-primary-black relative pb-[150px] h-full;

  &__step {
    span {
      @apply bg-secondary-block-blue text-secondary-blue-green inline-block px-[24px] py-[8px] md:rounded-desktop-internal rounded-mobile-external text-normal-S-semi;
    }
  }
  &__title {
    @apply truncate md:text-title-L text-title-XS mt-16px;
  }
  &__text {
    @apply mt-16px line-clamp-3 md:text-normal-S-regular text-normal-XS;
    height: 72px;

    :deep(a) {
      @apply text-primary-blue text-normal-XS-bold 
      md:text-normal-S-bold;
    }
  }
  &__image {
    @apply absolute right-0 bottom-0;
  }
  &__icon {
    @apply text-secondary-blue-green text-[24px] absolute left-[28px] bottom-[76px];
  }
}

.eqzaim-swiper {
  &__item {
    @apply h-auto;
  }
}
</style>
